import { useNavigate } from "react-router-dom";

function Home() {
  let navigate = useNavigate();

  return (
    <>
      {" "}
      <nav class="navbar sticky-top navbar-dark bg-dark">
        <div class="container-fluid">
          <span class="navbar-brand mb-0 h1">Plode Web Error Log</span>
          <span
            class="navbar-brand mb-0 h1"
            onClick={() => {
              navigate("/");
            }}
          >
            <button type="button" class="btn btn-outline-light">
              Home
            </button>
          </span>
        </div>
      </nav>
      <div
        class="container"
        style={{ position: "absolute", top: "50vh", paddingLeft: "30vw" }}
      >
        <div class="row">
          <div class="col-sm">
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => {
                navigate("/open");
              }}
            >
              Open Errors
            </button>
          </div>
          <div class="col-sm">
            <button
              type="button"
              class="btn btn-success"
              onClick={() => {
                navigate("/resolved");
              }}
            >
              Resolved Errors
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;

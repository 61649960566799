import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

var config = { "X-API-KEY": "d74382ad7c63460dbdb39954b8a1d424" };
function CloseStatus() {
  const [tableData, setTabledata] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);
  let navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get(
        "https://api.atatus.com/v2/projects/62f3761a6fe2755d2a06a099/errors?timeDur=1M&status=Resolved&limit=100&sortby=lastAt",
        { headers: config }
      )
      .then((res) => {
        // data = res.data.data;
        // console.log(data);
        // return data;
        var data = [];
        for (let [key, value] of Object.entries(res.data.data)) {
          // console.log(key, value);
          let x = (
            <tr class="table-success">
              <th scope="row">{parseInt(key) + 1}</th>
              <td>{value.message}</td>
              <td>{value.status}</td>
            </tr>
          );
          data.push(x);
        }
        setTabledata(data);
        setApiResponse(true);
        console.log(data);
      });
  }, []);
  return (
    <div className="App">
      <nav
        class="navbar sticky-top navbar-dark"
        style={{ backgroundColor: "#06304f" }}
      >
        <div class="container-fluid">
          <span class="navbar-brand mb-0 h1">Plode Web Error Log</span>
          <span
            class="navbar-brand mb-0 h1"
            onClick={() => {
              navigate("/");
            }}
          >
            <button type="button" class="btn btn-outline-light">
              Home
            </button>
          </span>
        </div>
      </nav>
      {!apiResponse ? (
        <div
          style={{
            position: "relative",
            display: "flex",
            top: "50vh",
            justifyContent: "center",
            justifyItems: "center",
            width: "100vw",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#06304f"}
            height={"100px"}
          ></ReactLoading>
        </div>
      ) : (
        <>
          <table class="table table-bordered">
            <thead class="table-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Error</th>
                <th scope="col">Status</th>
              </tr>
            </thead>

            <tbody>{tableData}</tbody>
          </table>
          <footer>
            <div class="text-center p-2" style={{ backgroundColor: "#fcd86a" }}>
              Only top 100 errors from the previous 30 days are displayed here.
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default CloseStatus;

import Home from "./home";
import OpenStatus from "./openStatus";
import CloseStatus from "./closeStatus";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      {/* <div className="App">
        <Header />
      </div> */}

      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/open" element={<OpenStatus />}></Route>
        <Route exact path="/resolved" element={<CloseStatus />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
